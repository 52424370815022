import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const theme = {
  primary: '#694361',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
}

export default new Vuetify({
  icons: {
    values: {
      minusSquare: {
        component: () => import('@/assets/icons/MinusSquare.vue'),
      },
      plusSquare: {
        component: () => import('@/assets/icons/PlusSquare.vue'),
      },
      home: {
        component: () => import('@/assets/icons/Home.vue'),
      },
      chevronDown: {
        component: () => import('@/assets/icons/ChevronDown.vue'),
      },
      circleNoFill: {
        component: () => import('@/assets/icons/CircleNoFill.vue'),
      },
      circleCheck: {
        component: () => import('@/assets/icons/CircleCheck.vue'),
      },
      eye: {
        component: () => import('@/assets/icons/Eye.vue'),
      },
      eyeSlash: {
        component: () => import('@/assets/icons/EyeSlash.vue'),
      },
      exclaimationCircle: {
        component: () => import('@/assets/icons/ExclaimationCircle.vue'),
      },
      xMark: {
        component: () => import('@/assets/icons/XMark.vue'),
      },
      square: {
        component: () => import('@/assets/icons/Square.vue'),
      },
      squareCheck: {
        component: () => import('@/assets/icons/SquareCheck.vue'),
      },
    },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
