import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import Cookies from 'js-cookie'
import regionData from '../data/regions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    refreshToken: undefined,
    loggedIn: null,
    currentUser: {},
    selectedOrg: null,
    bundles: [],
    cardToken: '',
    couponCode: '',
    couponDiscount: 0,
    couponError: '',
    order: {},
    orderDetails: {
      subscriptionID: 38,
      subscriptionQty: 0,
      outdoor: 0,
      indoor: 0,
    },
    billingDetails: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
    shippingDetails: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
    paymentDetails: {},
    orderTotal: {},
    systemError: '',
    paymentStatus: null,
    stripeId: '',
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn
    },
    selectedOrg(state) {
      return state.selectedOrg
    },
    couponDiscount(state) {
      return state.couponDiscount
    },
    couponError(state) {
      return state.couponError
    },
    couponCode(state) {
      return state.couponCode
    },
    billingDetails(state) {
      return state.billingDetails
    },
    bundles(state) {
      return state.bundles
    },
    orderDetails(state) {
      return state.orderDetails
    },
    orderTotal(state) {
      return state.orderTotal
    },
    systemError(state) {
      return state.systemError
    },
    paymentStatus(state) {
      return state.paymentStatus
    },
    currentUser(state) {
      return state.currentUser
    },
    stripeId(state) {
      return state.stripeId
    },
    refreshToken(state) {
      return state.refreshToken
    }
  },
  mutations: {
    setRefreshToken(state, payload) {
      state.refreshToken = payload
    },
    setSelectedOrg(state, payload) {
      state.selectedOrg = payload
    },
    setCouponCode(state, payload) {
      state.couponCode = payload
    },
    setCouponDiscount(state, payload) {
      state.couponDiscount = payload
    },
    setCouponError(state, payload) {
      state.couponError = payload
    },
    setOrder(state, payload) {
      state.order = payload
    },
    setBillingDetails(state, payload) {
      state.billingDetails = payload
    },
    setShippingDetails(state, payload) {
      state.shippingDetails = payload
    },
    setBundles(state, payload) {
      state.bundles = payload
    },
    setCardToken(state, payload) {
      state.cardToken = payload
    },
    setPaymentDetails(state, payload) {
      state.paymentDetails = payload
    },
    setOrderDetails(state, payload) {
      state.orderDetails = payload
    },
    setCouponDiscount(state, payload) {
      state.couponDiscount = payload
    },
    setOrderTotal(state, payload) {
      state.setOrderTotal = payload
    },
    setSystemError(state, payload) {
      state.systemError = payload
    },
    setPaymentStatus(state, payload) {
      state.paymentStatus = payload
    },
    setLogin(state, payload) {
      state.loggedIn = payload
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
    setStripeId(state, payload) {
      state.stripeId = payload
    },
  },
  actions: {
    removeError({ commit }) {
      setTimeout(() => {
        commit('setSystemError', null)
      }, 4000)
    },
    async logIn({ commit, dispatch }, payload) {
      await axios
        .post(`${process.env.VUE_APP_PORTAL}/login`, payload)
        .then((response) => {
          if (response.status === 200) {
            commit('setLogin', payload.email)
            const { data } = response.data
            // Cookies.set('refreshToken', data.refreshToken, {
            //   expires: 30,
            //   domain: "noiseaware.com",
            // })
            commit('setRefreshToken', data.refreshToken)
            dispatch('refreshUserToken')
            const userDataPayload = {
              url: process.env.VUE_APP_PORTAL,
              // token: Cookies.get('refreshToken'),
              token: data.refreshToken
            }
            dispatch('fetchUserData', userDataPayload)
          }
        })
        .catch((err) => {
          commit('setLogin', null)
          commit('setSystemError', err.response.data.detail)
          dispatch('removeError')
        })
    },
    async logOut({ commit }) {
      // Cookies.remove('refreshToken', { domain: process.env.VUE_APP_COOKIE_URL })
      commit('setRefreshToken', undefined)
      commit('setLogin', null)
      commit('setCurrentUser', {})
    },
    async refreshUserToken({state, commit}) {
      // const refreshToken = Cookies.get('refreshToken')
      const refreshToken = state.refreshToken
      if (refreshToken) {
        await axios
        .post(`${process.env.VUE_APP_PORTAL}/refresh`, { refreshToken })
        .then((res) => {
          // Cookies.set('refreshToken', res.data.data.userToken)
          commit('setRefreshToken', res.data.data.userToken)
        })
        // .catch(() => Cookies.remove('refreshToken'))
        .catch(() => commit('setRefreshToken', undefined))
      }
    },
    async fetchUserData({ state, commit, dispatch }, payload) {
      if (payload.token) {
        await axios
        .get(`${payload.url}/user/`, {
          headers: {
            authorization: `${payload.token}`,
          },
        })
        .then((res) => {
          commit('setCurrentUser', res.data.data)
          if (!state.loggedIn) commit('setLogin', res.data.data.email)
          if(res.data.data?.orgs.length === 1) commit('setSelectedOrg', res.data.data.orgs[0].id)
          if (!res.data.data.orgs) {
            commit(
              'setSystemError',
              "You don't seem to be in any organizations. If this is in error, contact support.",
            )
            dispatch('removeError')
          }
        })
        .catch((err) => {
          commit('setSystemError', err.response.data.detail)
          dispatch('removeError')
        })
      }
      
    },
    async fetchBundles({ state, commit }) {
      const body = { filter: [], sort: [] }
      await axios
        .post(`${process.env.VUE_APP_PORTAL}/bundles/list`, body)
        .then((res) => {
          commit('setBundles', res.data.data)
        })
    },
    async getUserStripeId({ state, commit, dispatch }) {
      await axios
        .get(
          `${process.env.VUE_APP_PORTAL}/billing/organization/orgID/${state.selectedOrg}`,
          {
            headers: {
              // authorization: `${Cookies.get('refreshToken')}`,
              authorization: state.refreshToken
            },
          },
        )
        .then((response) => {
          commit('setStripeId', response.data.data.stripeID)
        })
        .catch((err) => {
          commit('setSystemError', err.response.data.detail)
          dispatch('removeError')
        })
    },
    buildPaymentDetails({ state, commit }) {
      const data = {
        billingDetails: state.billingDetails,
        shippingDetails: state.shippingDetails,
        couponCode: state.couponCode,
        paymentSourceID: state.cardToken.id || '',
        order: state.order,
      }
      const billCountry =
        regionData[data.billingDetails.country.toLowerCase()].alpha2
      const shipCountry =
        regionData[data.shippingDetails.country.toLowerCase()].alpha2
      data.billingDetails.country = billCountry
      data.shippingDetails.country = shipCountry

      if (state.stripeId.length > 0) {
        data.stripeID = state.stripeId
        data.orgID = state.selectedOrg
      }
      commit('setPaymentDetails', data)
    },
    async makePayment({ state, commit, dispatch }) {
      dispatch('buildPaymentDetails')
      // const refreshToken = Cookies.get('refreshToken')
      const refreshToken = state.refreshToken
      let config = {}
      if (refreshToken) {
        config = {
          headers: {
            Authorization: `${refreshToken}`,
          },
        }
      }

      await axios
        .post(
          `${process.env.VUE_APP_PORTAL}/payments/`,
          state.paymentDetails,
          config,
        )
        .then((res) => {
          commit(
            'setPaymentStatus',
            res.data.data.subscription.latestInvoice.id,
          )
        })
        .catch((err) => {
          commit('setSystemError', err.response.data.detail)
          dispatch('removeError')
        })
    },
    handleCouponError({commit}, message) {
      commit('setCouponDiscount', 0)
      commit('setCouponError', message)
      setTimeout(() => {
        commit('setCouponError', '')
      }, 3000)
    },
    validateCoupon({ commit, dispatch}, payload) {
      commit('setCouponError', '')
      if (payload === '') dispatch('handleCouponError', 'Please enter a coupon code')
      else { 
        axios
        .get(`${process.env.VUE_APP_PORTAL}/payments/coupons?code=${payload}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.expiration) dispatch('handleCouponError', 'Coupon is expired')
            else commit('setCouponDiscount', res.data.data.amount)
          }
        })
        .catch((err) => {
          dispatch('handleCouponError', 'Invalid Coupon')
        })
      }
    },
    calculateTotal({ state, commit, dispatch }) {
      commit('setCouponError', '')
      dispatch('buildPaymentDetails')
      axios
        .get(
          `${process.env.VUE_APP_PORTAL}/payments/calculateTotal`,
          state.paymentDetails,
        )
        .then((res) => {
          commit('setOrderTotal', res.data.data)
        })
        .catch((err) => {
          commit('setSystemError', err.response.data.detail)
          dispatch('removeError')
        })
    },
  },
})
