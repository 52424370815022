<template>
  <v-app>
    <v-main>
      <collapse-transition>
        <v-banner
          v-if="systemError"
          single-line
          style="
            position: absolute;
            background-color: red;
            width: 100%;
            color: white;
          "
        >
          {{ systemError }}
        </v-banner>
      </collapse-transition>
      <h1 class="heading">{{ $route.meta.title }}</h1>
      <div
        class="d-flex justify-center"
        style="width: 100%; margin-bottom: 100px"
      >
        <v-card class="content-area pb-14 pt-2" min-width="90%">
          <router-view />
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
// import Cookies from "js-cookie";

export default {
  name: "App",
  computed: {
    ...mapGetters(["systemError", "refreshToken"]),
  },
  beforeCreate() {
    this.$store.dispatch("fetchBundles");
    this.$store.dispatch("refreshUserToken");
  },
  mounted() {
    const userDataPayload = {
      url: process.env.VUE_APP_PORTAL,
      // token: Cookies.get("refreshToken"),
      token: this.refreshToken,
    };
    if (userDataPayload.token) {
      this.$store.dispatch("fetchUserData", userDataPayload);
    }
  },
};
</script>
<style lang="scss">
* {
  font-family: "Degular", sans-serif !important;
}
#app,
.v-expansion-panel-content {
  background-color: #f9f7f3;
}
.heading {
  font-size: 3rem;
  margin-left: 5%;
  color: #456e6c;
}
.content-area {
  max-width: 90% !important;
  background-color: #f1ede6 !important;
}
.ui-link {
  text-decoration: underline;
  color: #694361;
  cursor: pointer;
}
.v-input {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.v-tabs-bar {
  background-color: #f1ede6 !important;
}
.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
  max-height: 34px;
  min-height: 34px !important;
}
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 6px !important;
}
.v-text-field--filled.v-input--dense .v-label {
  top: 8px !important;
}
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__selections {
  padding-top: 7px !important;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) {
  .v-input__append-inner {
    margin-top: 7px !important;
  }
}
.v-icon__component {
  height: 18px;
  width: 18px;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #f0f0f0 inset;
}
.v-dialog__content {
  align-items: flex-start !important;
  margin-top: 50px;
}
</style>
