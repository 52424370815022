const regions = {
  us: {
    name: 'United States',
    alpha2: 'US',
    states: [
      {
        name: 'Alabama',
        alpha2: 'AL',
      },
      {
        name: 'Alaska',
        alpha2: 'AK',
      },
      {
        name: 'American Samoa',
        alpha2: 'AS',
      },
      {
        name: 'Arizona',
        alpha2: 'AZ',
      },
      {
        name: 'Arkansas',
        alpha2: 'AR',
      },
      {
        name: 'California',
        alpha2: 'CA',
      },
      {
        name: 'Colorado',
        alpha2: 'CO',
      },
      {
        name: 'Connecticut',
        alpha2: 'CT',
      },
      {
        name: 'Delaware',
        alpha2: 'DE',
      },
      {
        name: 'District Of Columbia',
        alpha2: 'DC',
      },
      {
        name: 'Federated States Of Micronesia',
        alpha2: 'FM',
      },
      {
        name: 'Florida',
        alpha2: 'FL',
      },
      {
        name: 'Georgia',
        alpha2: 'GA',
      },
      {
        name: 'Guam',
        alpha2: 'GU',
      },
      {
        name: 'Hawaii',
        alpha2: 'HI',
      },
      {
        name: 'Idaho',
        alpha2: 'ID',
      },
      {
        name: 'Illinois',
        alpha2: 'IL',
      },
      {
        name: 'Indiana',
        alpha2: 'IN',
      },
      {
        name: 'Iowa',
        alpha2: 'IA',
      },
      {
        name: 'Kansas',
        alpha2: 'KS',
      },
      {
        name: 'Kentucky',
        alpha2: 'KY',
      },
      {
        name: 'Louisiana',
        alpha2: 'LA',
      },
      {
        name: 'Maine',
        alpha2: 'ME',
      },
      {
        name: 'Marshall Islands',
        alpha2: 'MH',
      },
      {
        name: 'Maryland',
        alpha2: 'MD',
      },
      {
        name: 'Massachusetts',
        alpha2: 'MA',
      },
      {
        name: 'Michigan',
        alpha2: 'MI',
      },
      {
        name: 'Minnesota',
        alpha2: 'MN',
      },
      {
        name: 'Mississippi',
        alpha2: 'MS',
      },
      {
        name: 'Missouri',
        alpha2: 'MO',
      },
      {
        name: 'Montana',
        alpha2: 'MT',
      },
      {
        name: 'Nebraska',
        alpha2: 'NE',
      },
      {
        name: 'Nevada',
        alpha2: 'NV',
      },
      {
        name: 'New Hampshire',
        alpha2: 'NH',
      },
      {
        name: 'New Jersey',
        alpha2: 'NJ',
      },
      {
        name: 'New Mexico',
        alpha2: 'NM',
      },
      {
        name: 'New York',
        alpha2: 'NY',
      },
      {
        name: 'North Carolina',
        alpha2: 'NC',
      },
      {
        name: 'North Dakota',
        alpha2: 'ND',
      },
      {
        name: 'Northern Mariana Islands',
        alpha2: 'MP',
      },
      {
        name: 'Ohio',
        alpha2: 'OH',
      },
      {
        name: 'Oklahoma',
        alpha2: 'OK',
      },
      {
        name: 'Oregon',
        alpha2: 'OR',
      },
      {
        name: 'Palau',
        alpha2: 'PW',
      },
      {
        name: 'Pennsylvania',
        alpha2: 'PA',
      },
      {
        name: 'Puerto Rico',
        alpha2: 'PR',
      },
      {
        name: 'Rhode Island',
        alpha2: 'RI',
      },
      {
        name: 'South Carolina',
        alpha2: 'SC',
      },
      {
        name: 'South Dakota',
        alpha2: 'SD',
      },
      {
        name: 'Tennessee',
        alpha2: 'TN',
      },
      {
        name: 'Texas',
        alpha2: 'TX',
      },
      {
        name: 'Utah',
        alpha2: 'UT',
      },
      {
        name: 'Vermont',
        alpha2: 'VT',
      },
      {
        name: 'Virgin Islands',
        alpha2: 'VI',
      },
      {
        name: 'Virginia',
        alpha2: 'VA',
      },
      {
        name: 'Washington',
        alpha2: 'WA',
      },
      {
        name: 'West Virginia',
        alpha2: 'WV',
      },
      {
        name: 'Wisconsin',
        alpha2: 'WI',
      },
      {
        name: 'Wyoming',
        alpha2: 'WY',
      },
    ],
  },
  ca: {
    name: 'Canada',
    alpha2: 'CA',
    states: [
      {
        name: 'Alberta',
        alpha2: 'AB',
      },
      {
        name: 'British Columbia',
        alpha2: 'BC',
      },
      {
        name: 'Manitoba',
        alpha2: 'MB',
      },
      {
        name: 'New Brunswick',
        alpha2: 'NB',
      },
      {
        name: 'Newfoundland and Labrador',
        alpha2: 'NL',
      },
      {
        name: 'Northwest Territories',
        alpha2: 'NT',
      },
      {
        name: 'Nova Scotia',
        alpha2: 'NS',
      },
      {
        name: 'Nunavut',
        alpha2: 'NU',
      },
      {
        name: 'Ontario',
        alpha2: 'ON',
      },
      {
        name: 'Prince Edward Island',
        alpha2: 'PE',
      },
      {
        name: 'Quebec',
        alpha2: 'QC',
      },
      {
        name: 'Saskatchewan',
        alpha2: 'SK',
      },
      {
        name: 'Yukon Territory',
        alpha2: 'YT',
      },
    ],
  },
}

export default regions
